import "./Carrosel.css";
import icone from '../../Imagens/iconeSobreMim.png'

import foto1 from "../../Imagens/Eventos/2023ForumOestedoParana.jpeg";
import foto2 from "../../Imagens/Eventos/2023CongressoConsetram.jpeg"
import foto3 from '../../Imagens/Eventos/2022AcaodeNatal.jpeg'
import foto4 from "../../Imagens/Eventos/2023PalestraUnifacs.jpeg";
import foto5 from '../../Imagens/Eventos/2023BancaSENAI.jpeg'
import foto6 from '../../Imagens/Eventos/2021CredenciamentoMototaxi.jpeg'
import foto7 from '../../Imagens/Eventos/2022InauguracaoTerminais.jpeg'


const Carrosel = () => {
  return (
    <section className="container-carrosel">
      <div className="container-carrosel-titulo">
        <img src={icone} alt="imagem de icone" />
        <h2>GALERIA DE FOTOS</h2>
      </div>

      <div>
        <div className="sub-container-galeria">
          <img
            style={{ width: "40%", height: "52vw", borderRadius: "1vw" }}
            src={foto1}
            alt="foto de andré akio em eventos"
          />
          <div className="sub-sub-container-galeria1">
            <img
              style={{
                width: "45vw",
                height: "25vw",
                objectFit: "fill",
                marginBottom: "2vw",
                borderRadius: "1vw",
              }}
              src={foto2}
              alt="foto de andré akio em eventos"
            />
            <img
              style={{
                width: "45vw",
                height: "25vw",
                objectFit: "fill",
                marginBottom: "2vw",
                borderRadius: "1vw",
              }}
              src={foto3}
              alt="foto de andré akio em eventos"
            />
          </div>
        </div>
        <div className="sub-container-galeria">
          <div>
            <div style={{ display: "flex" }}>
              <img
                style={{ height: "100%", width: "21vw",marginRight:"2vw",borderRadius: "1vw" }}
                src={foto4}
                alt="foto de andré akio em eventos"
              />
              <img
                style={{ height: "100%", width: "28vw",marginRight:"4vw",borderRadius: "1vw" }}
                src={foto5}
                alt="foto de andré akio em eventos"
              />
            </div>
            <img
              style={{
                width: "51vw",
                height: "25vw",
                objectFit: "fill",
                marginBottom: "2vw",
                borderRadius: "1vw",
                marginTop:"2vw"
              }}
              src={foto6}
              alt="foto de andré akio em eventos"
            />
          </div>
          <img
            style={{ width: "40%", height: "48vw",borderRadius: "1vw" }}
            src={foto7}
            alt="foto de andré akio em eventos"
          />
        </div>
      </div>
    </section>
  );
};

export default Carrosel;
