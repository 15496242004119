import React, { useState, useEffect } from "react";
import "./Contribuicoes.css";
import logoPartidoN from "../../Imagens/Bandeira_N_Slogan_Branco-1.png";
import iconeTitulo from "../../Imagens/iconeSobreMim.png";
import PlanejamentoMicareta from '../../Imagens/Eventos/2023PlanejamentoMicareta.jpeg'
import PalestraUnifan from '../../Imagens/Eventos/2023PalestraUnifacs.jpeg'
import InstitutoPensar from "../../Imagens/Eventos/2023InstitutoPensarFeira.jpeg"
import ForumParana from '../../Imagens/Eventos/2023ForumOestedoParana.jpeg'
import ForumBaianoItabuna from '../../Imagens/Eventos/2023ForumBaianoItabuna.jpeg'
import BancaSenai from '../../Imagens/Eventos/2023BancaSENAI.jpeg'
import BancaHackathon from '../../Imagens/Eventos/2023BancaHackathon.jpeg'
import InaguracaoTerminais from '../../Imagens/Eventos/2022InauguracaoTerminais.jpeg'
import ForumaianoDois from "../../Imagens/Eventos/2022ForumBaianoDois.jpeg"
import ForumBaiano from '../../Imagens/Eventos/2022ForumBaiano.jpeg'
import AcaoNatal from '../../Imagens/Eventos/2022AcaodeNatal.jpeg'
import PalestraSemanado from '../../Imagens/Eventos/2021PalestraSemanadoTutorCatedra.jpeg'
import PalestraLaboratorio from '../../Imagens/Eventos/2021PalestraLaboratorioAnalise.jpeg'
import EntrevistaDiretoria from '../../Imagens/Eventos/2021EntrevistaDiretoriadeTransportes.jpeg'
import CredenciamentoMototaxa from '../../Imagens/Eventos/2021CredenciamentoMototaxi.jpeg'
import Capacitacao from '../../Imagens/Eventos/2021CapacitacaoServidores.jpeg'

const Contribuicoes = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const cards = [
    {
      img: PlanejamentoMicareta,
      texto: "PLANEJAMENTO MICARETA",
      ano: "2023",
    },
    {
      img: PalestraUnifan,
      texto: "PALESTRA UNIFACS",
      ano: "2023",
    },
    {
      img: InstitutoPensar,
      texto: "INSTITUTO PENSAR FEIRA ",
      ano: "2023",
    },
    {
      img: ForumParana,
      texto: "FORUM PARANA",
      ano: "2023",
    },
    {
      img: ForumBaianoItabuna,
      texto: "FORUM BAIANO ITABUNA",
      ano: "2023",
    },
    {
      img: BancaSenai,
      texto: "BANCA SENAI",
      ano: "2023",
    },
    {
      img: BancaHackathon,
      texto: "BANCA HACKATHON",
      ano: "2023",
    },
    {
      img: InaguracaoTerminais,
      texto: "INAUGURAÇÃO DOS TERMINAIS",
      ano: "2022",
    },
    {
      img: ForumaianoDois,
      texto: "FORUM BAIANO FEIRA",
      ano: "2022",
    },
    {
      img: ForumBaiano,
      texto: "FORUM BAIANO",
      ano: "2022",
    },
    {
      img: AcaoNatal,
      texto: "AÇÃO DE NATAL",
      ano: "2022",
    },
    {
      img: PalestraSemanado,
      texto: "PALESTRA SEMANA DO TUTOR CATEDRA",
      ano: "2021",
    },
    {
      img: PalestraLaboratorio,
      texto: "PALESTRA LABORATÓRIO ANALISE",
      ano: "2021",
    },
    {
      img: EntrevistaDiretoria,
      texto: "ENTREVISTA DIRETORIA DE TRANSPORTE",
      ano: "2021",
    },
    {
      img: CredenciamentoMototaxa,
      texto: "CREDENCIAMENTO MOTOTAXI",
      ano: "2021",
    },
    {
      img: Capacitacao,
      texto: "CAPACITAÇÃO DE SERVIDORES",
      ano: "2021",
    },
  ];

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? cards.length - 3 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === cards.length - 3 ? 0 : prevIndex + 1
    );
  };

  // Avanço automático a cada 3 segundos
  useEffect(() => {
    const interval = setInterval(() => {
      handleNextClick();
    }, 3000); // Tempo em milissegundos (3000ms = 3s)

    return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
  }, [currentIndex]); // Atualiza o efeito sempre que o currentIndex muda

  return (
    <section id="feitos" className="container-contribuicoes">
      <div className="contribuicoes-img-partido">
        <img alt="logo do partido NOVO" src={logoPartidoN} />
      </div>
      <div className="container-titulo-contribuioes">
        <img alt="icone do titulo" src={iconeTitulo} />
        <h1 className="titulo-contribuicoes">FEITOS</h1>
      </div>
      <div className="container-contribuicoes-card">
        <button onClick={handlePrevClick} className="nav-button">
          {"<"}
        </button>
        {cards.slice(currentIndex, currentIndex + 3).map((card, index) => (
         
            <div className="contribuicoes-card">
              <img
                className="contribuicoes-imagem-card"
                alt="Imagem da ação"
                src={card.img}
              />
              <p className="contribuicoes-texto-card">{card.texto}</p>
              <span className="contribuicoes-ano-card">{card.ano}</span>
            </div>
         
        ))}
        <button onClick={handleNextClick} className="nav-button">
          {">"}
        </button>
      </div>

      {/* Indicadores de Bolinhas */}
      <div className="contribuicoes-indicadores">
        {Array.from({ length: Math.ceil(cards.length / 3) }).map((_, index) => (
          <div
            key={index}
            className={`indicador-bolinha ${
              currentIndex === index * 3 ? "ativo" : ""
            }`}
          ></div>
        ))}
      </div>
    </section>
  );
};

export default Contribuicoes;
