import "./Propostas.css";
import icone from "../../Imagens/iconeSobreMim.png";
import ftAnreAkio from "../../Imagens/ftAndre.png";
import iconeSeguranca from "../../Imagens/iconeSeguranca.png";
import iconeSaude from "../../Imagens/iconeSaude.png";
import iconeTransporte from "../../Imagens/iconeTransporte.png";
import { useEffect, useState } from "react";
import iconeTurismo from "../../Imagens/iconeTurismo.png";
import iconeInfraestrutura from "../../Imagens/iconeInfraestrutura.png";
import iconeCiencia from "../../Imagens/iconeCiencia.png";
import iconeEducacao from "../../Imagens/iconeEducacao.png";
import iconeTransito from "../../Imagens/iconeTransito.png";

const Propostas = () => {
  const propostas = [
    {
      titulo: "SEGURANÇA",
      icone: iconeSeguranca,
      texto:
        "Implantar sistema de gerenciamento de ocorrências; ampliar a rede de monitoramento com câmeras 4K nos bairros e distritos; retorno da guarda municipal nos Terminais; termo de cooperação para um CCO multi-agência (PM, PRF, CBM, SAMU).",
    },
    {
      titulo: "SAÚDE",
      icone: iconeSaude,
      texto:
        "Ampliação do SAMU (Base Tomba e Base Nóide); meta de uma Policlínica em cada Distrito; criar aplicativo da Saúde para marcação de consultas e exames com possibilidade de avaliação do atendimento.",
    },
    {
      titulo: "TRANSPORTE PÚBLICO",
      icone: iconeTransporte,
      texto:
        "Criar a linha de Ipuaçu; criar linhas inter-bairros; fazer a via exclusiva do BRT na Getúlio; ampliar e criar o aplicativo do PACE; integrar o transporte alternativo com a Bilhetagem Eletrônica; promover cursos de capacitação para os condutores de Táxi, Mototáxi, Escolar, STPAC e SIT.",
    },
    {
      titulo: "INFRAESTRUTURA",
      icone: iconeInfraestrutura,
      texto:
        "Calçamento de estradas dos Distritos: estradas da Candeia Grossa, Alecrim Miúdo, Jacu, Candeal, Tapera, Caatinga, Carro Quebrado, Ovo da Ema, Formiga, Água Grande, Lagoa da Camisa, Casa Nova, Santa Rosa, Formosa, Pedra da Canoa, São Domingos, São Roque, Bom Viver, Doutor, Jenipapo, Pau Seco, Rosário, Campestre, Escoval.Pavimentação de ruas e reforma de Praças na cidade e nos Distritos.",
    },
    {
      titulo: "TURISMO",
      icone: iconeTurismo,
      texto:
        "Turismo Cultural: restauração dos prédios históricos para abertura e visitação.Turismo de Aventura: construção de trilhas para ciclismo.Turismo Religioso: explorar Morros com construção de praças de contemplação; construção da pista de decolagem para vôo livre no Morro de São José.Ecoturismo: construção da Orla do Jacuípe com área de lazer.",
    },
    {
      titulo: "CIÊNCIA E TECNOLOGIA",
      icone: iconeCiencia,
      texto:
        "Realizar eventos voltados para educação científica no Parque do Saber; tornar obrigatória a aula de ciência experimental nas escolas com o uso do planetário; buscar parcerias com as universidades para promoção da Feira de Ciências; equipar os terminais de transbordo com tela interativa para acompanhamento das linhas; ampliar a internet gratuita Feira Digital.",
    },
    {
      titulo: "TRÂNSITO",
      icone: iconeTransito,
      texto:
        "Criar a Escola Pública de Trânsito; área de embarque/desembarque para Transporte por Aplicativo; sincronizar os semáforos; construir pistas de aceleração nos retornos; conceito de Ruas Completas nas Praças do Gastão, Kalilândia, Tomba.",
    },
    {
      titulo: "EDUCAÇÃO",
      icone: iconeEducacao,
      texto:
        "Instituir as matérias de Inglês, Música e Educação no Trânsito nas séries primárias; promover olimpíadas municipais; meta de uma Creche-Escola em cada Distrito; ampliar a oferta e horário de funcionamento das Creches; avaliar a frota do transporte escolar nos Distritos.",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? propostas.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === propostas.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Adicionando intervalo de tempo para trocar automaticamente
  useEffect(() => {
    const intervalId = setInterval(goToNext, 10000); // Muda a cada 5 segundos

    return () => clearInterval(intervalId); // Limpa o intervalo quando o componente desmonta
  }, []);

  return (
    <section id="propostas" className="container-propostas">
      <div className="container-titulo-propostas">
        <img
          className="proposta-titulo-img"
          alt="icone do titulo proposta"
          src={icone}
        />
        <h2 className="proposta-titulo-titulo">PROPOSTAS</h2>
      </div>
      <div className="sub-container-proposta">
        <img
          alt="foto de andré akio "
          src={ftAnreAkio}
          className="sub-container-proposta-img"
        />
        <div className="carousel-container">
          <div className="carousel">
            <div className="carousel-content">
              <div className="sub-container-propostas-propostas-titulo">
                <img
                  className="img-sub-sontainer-proposta"
                  alt={`icone ${propostas[currentIndex].titulo}`}
                  src={propostas[currentIndex].icone}
                />
                <h3 className="container-propostas-propostas-titulo">
                  {propostas[currentIndex].titulo}
                </h3>
              </div>
              <p className="container-propostas-propostas-texto">
                {propostas[currentIndex].texto}
              </p>
            </div>
            <div className="carousel-controls">
              <button onClick={goToPrevious} className="carousel-arrow">
                &lt;
              </button>
              <button onClick={goToNext} className="carousel-arrow">
                &gt;
              </button>
            </div>
            <div className="carousel-indicators">
              {propostas.map((_, index) => (
                <span
                  key={index}
                  className={`indicator ${
                    index === currentIndex ? "active" : ""
                  }`}
                  onClick={() => setCurrentIndex(index)}
                ></span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Propostas;
