

// import "./Video.css";
// import React from "react";

// const Video = () => {
//   return (
//     <div style={{ height: "87vh", width: "100%" }}>
//       <iframe
//         width="100%"
//         height="100%"
//         src="https://www.youtube.com/embed/fqkbOHW4iOc"
//         frameBorder="0"
//         allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
//         allowFullScreen
//         title="YouTube Video"
//       ></iframe>
//     </div>
//   );
// };

// export default Video;


import "./Video.css";
import React from "react";
import VideoImage from "./fotoCapa.webp"; // Atualize este caminho para o caminho correto da imagem

const Video = () => {
  return (
    <div className="video-container" >
      <iframe
        className="video-frame"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/fqkbOHW4iOc"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="YouTube Video"
      ></iframe>
      <img src={VideoImage} alt="Video Thumbnail" className="video-image" />
    </div>
  );
};

export default Video;
