import Assinatura from "../../Componentes/Assinatura/Assinatura";
import Carrosel from "../../Componentes/Carrosel/Carrosel";
import Header from "../../Componentes/Header/Header";
import PreeFooter from "../../Componentes/PreeFooter/PreeFooter";



const GaleriaPag = () => {
    return (
        <>
            <Header />
            <Carrosel/>
            <PreeFooter />
            <Assinatura/>
        </>
    )
}

export default GaleriaPag;