import "./ChamadaDois.css";
import partidoNovo from "../../Imagens/NOVO_Slogan_Azul.png";
import partidoComBandeira from "../../Imagens/Bandeira_N_Slogan_Laranja.png";

const ChamadaDois = () => {
  return (
    <section className="container-fluid container-chamada-dois">
      <img
        className="img-chamada"
        alt="Partido NOVO"
        src={partidoComBandeira}
      />
      <h1 className="">
        VOTE ANDRÉ AKIO <span className="numero-voto">30123</span> PELO
      </h1>
      <img
        className="img-chamada2"
        alt="logo do partido novo com bandeira"
        src={partidoNovo}
      />
    </section>
  );
};

export default ChamadaDois;
