import "./Noticias.css";
import React, { useState, useEffect } from "react";
import logoPartidoN from "../../Imagens/Bandeira_N_Slogan_Branco-1.png";
import iconeTitulo from "../../Imagens/iconeSobreMim.png";

const Noticias = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const cards = [
    {
      img: "https://imagens.acordacidade.com.br/wp-content/uploads/2022/07/24144505/onibus-urbano_-Foto-Divulgacao-Secom.jpg",
      texto:
        "Prefeitura de Feira implanta nova linha de transporte público para Tiquaruçu",
      ano: "2019",
      url: "https://www.acordacidade.com.br/feira-de-santana/prefeitura-de-feira-implanta-nova-linha-de-transporte-publico-para-tiquarucu/?utm_source=whatsapp&utm_medium=referral&utm_campaign=share_alongisde",
    },
    {
      img: "https://imagens.acordacidade.com.br/wp-content/uploads/2022/10/10155947/Transporte-Foto-Andrews-Pedra-Branca-8.jpg",
      texto: "Ponto de ônibus é instalado ao lado do Mercado de Arte",
      ano: "2015 - 2024",
      url: "https://www.acordacidade.com.br/feira-de-santana/ponto-de-onibus-e-instalado-ao-lado-do-mercado-de-arte/?utm_source=whatsapp&utm_medium=referral&utm_campaign=share_alongisde",
    },
    {
      img: "https://feiradesantana.ba.gov.br/imagens/noticias/07112022112947.jpg",
      texto:
        "Novas linhas alimentadoras reforçam o transporte público em Feira",
      ano: "2023",
      url: "https://feiradesantana.ba.gov.br/secom/whatsapp.asp?idn=31572",
    },
    {
      img: "https://imagens.acordacidade.com.br/wp-content/uploads/2022/12/01153223/Expresso-Novo-Centro-Foto-Andrews-Pedra-Branca-1.jpg",
      texto:
        "Prefeitura disponibiliza nova linha urbana para Terminal Rodoviário",
      ano: "2022",
      url: "https://www.acordacidade.com.br/feira-de-santana/prefeitura-disponibiliza-nova-linha-urbana-para-terminal-rodoviario/?utm_source=whatsapp&utm_medium=referral&utm_campaign=share_alongisde",
    },
    {
      img: "https://imagens.acordacidade.com.br/wp-content/uploads/2023/05/30111814/Terminal-de-onibus-Foto-Andrews-Pedra-Branca-3.jpg",
      texto: "Prefeitura de Feira de Santana inaugura Terminal Ayrton Senna",
      ano: "2022",
      url: "https://www.acordacidade.com.br/feira-de-santana/prefeitura-de-feira-de-santana-inaugura-terminal-ayrton-senna/?utm_source=whatsapp&utm_medium=referral&utm_campaign=share_alongisde",
    },
    {
      img: "https://feiradesantana.ba.gov.br/imagens/noticias/22122022151350.jpg",
      texto:
        "Crianças fazem tour por Feira em ônibus iluminado na temática de Natal",
      ano: "2022",
      url: "https://www.feiradesantana.ba.gov.br/servicos.asp?titulo=Crian%C3%A7as-fazem-tour-por-Feira-em-%C3%B4nibus-iluminado-na-tem%C3%A1tica-de-Natal.html&id=16&link=secom/noticias.asp&idn=31937#noticias",
    },
    {
      img: "https://imagens.acordacidade.com.br/wp-content/uploads/2022/12/07154108/forumdemobilidade_edsantos_acordacidade-5.jpeg",
      texto:
        "1º Fórum de Mobilidade é realizado em Feira de Santana e reúne diversos gestores do transporte público",
      ano: "2022",
      url: "https://www.acordacidade.com.br/feira-de-santana/1o-forum-de-mobilidade-e-realizado-em-feira-de-santana-e-reune-diversos-gestores-do-transporte-publico/?utm_source=whatsapp&utm_medium=referral&utm_campaign=share_alongisde",
    },
    {
      img: "https://feiradesantana.ba.gov.br/imagens/noticias/07122022111926.jpg",
      texto:
        "Feira de Santana reúne gestores de transporte no 1º Fórum de Mobilidade",
      ano: "2022",
      url: "https://www.feiradesantana.ba.gov.br/servicos.asp?titulo=Feira-de-Santana-re%C3%BAne-gestores-de-transporte-em-F%C3%B3rum-de-Mobilidade.html&id=16&link=secom/noticias.asp&idn=31818#noticias",
    },
    {
      img: "https://feiradesantana.ba.gov.br/imagens/noticias/08122022204610.jpg",
      texto:
        "Feira discute a Mobilidade Ativa alinhada ao desenvolvimento sustentável",
      ano: "2022",
      url: "https://www.feiradesantana.ba.gov.br/servicos.asp?titulo=Feira-discute-a-Mobilidade-Ativa-alinhada-ao-desenvolvimento-sustent%C3%A1vel.html&id=16&link=secom/noticias.asp&idn=31839#noticias",
    },
    {
      img: "https://imagens.acordacidade.com.br/wp-content/uploads/2022/09/18140105/transbordo-noide-cerqueira-e-panpalona-18-09-2022-Foto-Izinaldo-Barreto-19.jpg",
      texto:
        "Prefeitura reforça linhas de ônibus e vans no dia das eleições em Feira de Santana",
      ano: "2022",
      url: "https://www.acordacidade.com.br/feira-de-santana/prefeitura-reforca-linhas-de-onibus-e-vans-no-dia-das-eleicoes-em-feira-de-santana/?utm_source=whatsapp&utm_medium=referral&utm_campaign=share_alongisde",
    },
    {
      img: "https://imagens.acordacidade.com.br/wp-content/uploads/2022/11/11145351/galeria-transporte-andrews-pedra-branca3-onibus-publico-prefeitura-feira-enem-640.jpg",
      texto: "Ônibus extras reforçam o acesso aos locais do Enem neste domingo",
      ano: "2022",
      url: "https://www.acordacidade.com.br/feira-de-santana/onibus-extras-reforcam-o-acesso-aos-locais-de-enem-domingo/?utm_source=whatsapp&utm_medium=referral&utm_campaign=share_alongisde",
    },
    {
      img: "https://imagens.acordacidade.com.br/wp-content/uploads/2023/06/27082347/transporte-escolar-privado.jpg",
      texto:
        "Vistoria do transporte escolar privado em Feira começa dia 3 de julho",
      ano: "2022",
      url: "https://www.acordacidade.com.br/feira-de-santana/vistoria-do-transporte-escolar-privado-em-feira-comeca-dia-3-de-julho/?utm_source=whatsapp&utm_medium=referral&utm_campaign=share_alongisde",
    },
    {
      img: "https://imagens.acordacidade.com.br/wp-content/uploads/2023/06/22131426/Transporte-Publico-_-Foto-Secom.jpg",
      texto:
        "Prefeitura de Feira coloca ônibus extras durante o São João nos distritos",
      ano: "2022",
      url: "https://www.acordacidade.com.br/feira-de-santana/prefeitura-de-feira-coloca-onibus-extras-durante-o-sao-joao-nos-distritos/?utm_source=whatsapp&utm_medium=referral&utm_campaign=share_alongisde",
    },
    {
      img: "https://blogdafeira.com.br/home/wp-content/uploads/2023/05/Terminal-de-onibus-Foto-Andrews-Pedra-Branca-4-420x280_c.jpg",
      texto:
        "Terminal Ayrton Senna facilita vida dos passageiros da zona norte de Feira",
      ano: "2022",
      url: "https://blogdafeira.com.br/home/2023/05/29/terminal-ayrton-senna-facilita-vida-dos-passageiros-da-zona-norte-de-feira/",
    },
    {
      img: "https://imagens.acordacidade.com.br/wp-content/uploads/2023/04/19160615/vistoria_pj_acordacidade-3.jpeg",
      texto:
        "Vistoria dos trios elétricos que irão desfilar na Micareta de Feira acontece até esta quinta-feira (20)",
      ano: "2022",
      url: "https://www.acordacidade.com.br/micareta/vistoria-dos-trios-eletricos-que-irao-desfilar-na-micareta-de-feira-acontece-ate-esta-quinta-feira-20/?utm_source=whatsapp&utm_medium=referral&utm_campaign=share_alongisde",
    },
    {
      img: "https://feiradesantana.ba.gov.br/imagens/noticias/18042023171523.jpg",
      texto:
        "Expresso Micareta terá quatro linhas exclusivas de ônibus para o Circuito Maneca Ferreira",
      ano: "2022",
      url: "https://feiradesantana.ba.gov.br/secom/whatsapp.asp?idn=32955",
    },
  ];

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? cards.length - 3 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === cards.length - 3 ? 0 : prevIndex + 1
    );
  };

  // Avanço automático a cada 3 segundos
  useEffect(() => {
    const interval = setInterval(() => {
      handleNextClick();
    }, 3000); // Tempo em milissegundos (3000ms = 3s)

    return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
  }, [currentIndex]); // Atualiza o efeito sempre que o currentIndex muda
  return (
    <section id="noticias" className="container-noticias">
      <div className="noticias-img-partido">
        <img alt="logo do partido NOVO" src={logoPartidoN} />
      </div>
      <div className="container-titulo-contribuioes">
        <img alt="icone do titulo" src={iconeTitulo} />
        <h1 className="titulo-noticias">NOTÍCIAS QUE MENCIONAM ANDRÉ AKIO</h1>
      </div>
      <div className="container-noticias-card">
        <button onClick={handlePrevClick} className="nav-button">
          {"<"}
        </button>
        {cards.slice(currentIndex, currentIndex + 3).map((card, index) => (
          <a key={index} href={card.url} rel="noreferrer" target="_blank" className="noticias-card-link">
            <div className="noticias-card">
              <img
                className="noticias-imagem-card"
                alt="Imagem da ação"
                src={card.img}
              />
              <p className="noticias-texto-card">{card.texto}</p>
              {/* <span className="noticias-ano-card">{card.ano}</span> */}
            </div>
          </a>
        ))}
        <button onClick={handleNextClick} className="nav-button">
          {">"}
        </button>
      </div>

      {/* Indicadores de Bolinhas */}
      <div className="noticias-indicadores">
        {Array.from({ length: Math.ceil(cards.length / 3) }).map((_, index) => (
          <div
            key={index}
            className={`indicador-bolinha ${
              currentIndex === index * 3 ? "ativo" : ""
            }`}
          ></div>
        ))}
      </div>
    </section>
  );
};

export default Noticias;
