import './Assinatura.css'
import logoDev from '../../Imagens/logoDev.jpg'

const Assinatura = () => {
    return (
      <section className='container container-desenvolvedor'>
        <p className='texto-desenvolvido'>Desenvolvido por</p>
        <a href="https://vwasystems.com.br/" rel='noreferrer' target='_blank'>
                <img alt="Logo da V.W.A Systems (desenvolvedora do site)" src={logoDev } />
        </a>
      </section>
    );
}

export default Assinatura;