import "./Biografia.css";
import icone from "../../Imagens/iconeSobreMim.png";
import andreSobre from "../../Imagens/fotoAndreSobre.png";

const Biografia = () => {
  return (
    <section id="sobre" className="container container-sobre">
      <div className="container-titulo-sobre">
        <img className="img-icone-sobre" alt="icone sobre mim" src={icone} />
        <h1 className="titulo-sobre">SOBRE MIM</h1>
      </div>
      <div className="row flex-lg-row align-items-center">
        <div className="col-12 col-lg-6 mb-4 mb-lg-0">
          <img
            src={andreSobre}
            className="img-fluid"
            alt="Foto de André Akio - Candidato a Vereador em Feira de Santana"
            loading="lazy"
          />
        </div>
        <div className="col-12 col-lg-6">
          <div className="container-titulo-sobre-texto">
            <h1 className="titulo-sobre-texto">
              ANDRÉ AKIO - CANDIDATO A VEREADOR DE FEIRA DE SANTANA
              <img
                className="img-sobre-texto"
                alt="Imagem da bandeira do Brasil"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Flag_of_Brazil.svg/275px-Flag_of_Brazil.svg.png"
              />
            </h1>
          </div>
          <p className="lead texto-biografia">
            <strong>André Akio</strong>, cristão, casado e pai. Formado em ADM,
            nascido e criado em Feira de Santana. Trabalhou 10 anos no setor
            privado na área de T&D. Atualmente no serviço público na área de
            Mobilidade, foi Diretor de Transportes de Públicos da SMTT, criou
            linhas e ampliou o Transporte Público colocando em operação
            Terminais e Estações antes paradas. Promoveu Ação de Natal com
            crianças carentes e indicou a parada livre para mulheres à noite.
            Estas ações o levaram a Fóruns de Mobilidade na Bahia e no Brasil.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Biografia;
