import "./Chamada.css";
import partidoNovo from "../../Imagens/NOVO_Bandeira_Laranja.png";

const Chamada = () => {
  return (
    <section className="container-fluid container-chamada">
        <h1 className="">VOTE ANDRÉ AKIO <span className="numero-voto">30123</span> PELO</h1>
          <img className="img-chamada"
            alt="logo do partido novo com bandeira"
            src={partidoNovo}
          />
    </section>
  );
};

export default Chamada;
