import "./PreeFooter.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faSquareInstagram, faYoutube, faLinkedin, } from "@fortawesome/free-brands-svg-icons";
import logoPartido from "../../Imagens/NOVO_Slogan_Branco-1.png";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
const PreeFooter = () => {
  const ano = new Date();
  const anoAtual = ano.getFullYear();

   const location = useLocation();

   useEffect(() => {
     const scrollToSection = (hash) => {
       const element = document.getElementById(hash);
       if (element) {
         element.scrollIntoView({ behavior: "smooth" });
       }
     };

     if (location.hash) {
       scrollToSection(location.hash.substring(1)); // Remove o '#' do hash
     }
   }, [location]);

   const navigate = useNavigate();

   const handleNavigation = (sectionId) => {
     if (location.pathname === "/galeria") {
       navigate("/", { replace: true });
       setTimeout(() => {
         navigate(`/#${sectionId}`);
       }, 0);
     } else {
       navigate(`/#${sectionId}`);
     }
   };

   const handleSobreClick = () => {
     if (location.pathname === "/galeria") {
       // Recarrega a página inicial e rola até a seção "Sobre Mim"
       navigate("/", { replace: true });
       setTimeout(() => {
         navigate("/#sobre");
       }, 0);
     } else {
       navigate("/#sobre");
     }
  };
  
  return (
    <footer className=" container-fluid container-pree-footer">
      <div className="sub-container-pree-footer">
        <div className="item-sub-container-pree-footer ">
          <img
            style={{ width: "15vw" }}
            src={logoPartido}
            alt="logo do partido NOVO"
          />
        </div>
        <div className=" item-sub-container-pree-footer-maior">
          <ul className="nav-lista">
            <li className="nav-lista-item">
              <button
                onClick={handleSobreClick}
                className="nav-link header-nav "
                aria-current="page"
              >
                SOBRE MIM
              </button>
            </li>
            <li className="nav-lista-item">
              <button
                onClick={() => handleNavigation("feitos")}
                className="nav-link header-nav"
              >
                FEITOS
              </button>
            </li>
            <li className="nav-lista-item">
              <button
                onClick={() => handleNavigation("propostas")}
                className="nav-link header-nav"
              >
                PROPOSTAS
              </button>
            </li>
            <li className="nav-lista-item">
              <button
                onClick={() => handleNavigation("noticias")}
                className="nav-link header-nav"
              >
                NOTÍCIAS
              </button>
            </li>
          </ul>
        </div>
        <div className="header-container-redes-sociais">
          <div className="header-container-redes-sociais-titulo">
            <span>MINHAS REDES SOCIAIS</span>
          </div>
          <ul className="header-redes-sociais" style={{ paddingLeft: "0px" }}>
            <li className="header-redes-sociais-icone">
              <a
                href="https://www.linkedin.com/in/akio-ogasawara-a81959123/"
                rel="noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon
                  icon={faLinkedin}
                  style={{ color: "#ffffff" }}
                />
              </a>
            </li>
            <li className="header-redes-sociais-icone">
              <a
                href="https://www.instagram.com/andreakio.oga/"
                rel="noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon
                  icon={faSquareInstagram}
                  style={{ color: "#ffffff" }}
                />
              </a>
            </li>
            <li className="header-redes-sociais-icone">
              <a
                href="https://www.youtube.com/@AndreAkio-zb9uo"
                rel="noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon
                  icon={faYoutube}
                  style={{ color: "#ffffff" }}
                />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="container-pree-footer-direitos   border-top">
        <p style={{ fontSize: "1.2vw", margin: 0, fontFamily: "Roboto" }}>
          © Copyright {anoAtual} Todos os direitos Reservados
        </p>
      </div>
      <div className="container-arrow-up">
        <a href="#">
          <FontAwesomeIcon icon={faArrowUp} />
        </a>
      </div>
    </footer>
  );
};

export default PreeFooter;
