
import './App.css';
import Assinatura from './Componentes/Assinatura/Assinatura';
import Biografia from './Componentes/Biografia/Biografia';
import Chamada from './Componentes/Chamada/Chamada';
import ChamadaDois from './Componentes/ChamadaDois/ChamadaDois';
import Contribuicoes from './Componentes/Contribuicoes/Contribuicoes';
import Header from './Componentes/Header/Header';
import Noticias from './Componentes/Noticias/Noticias';
import PreeFooter from './Componentes/PreeFooter/PreeFooter';
import Propostas from './Componentes/Propostas/Propostas';
import Video from './Componentes/Video/video';

function App() {
  return (
    <>
      <Header />
      <Video />
      <Chamada />
      <Biografia />
      <Contribuicoes/>
      <ChamadaDois/>
      <Propostas />
      <Noticias/>
      <PreeFooter />
      <Assinatura/>
    </>
  );
}

export default App;
